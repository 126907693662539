import Button from "components/Button";
import Modal from "components/Modal";
import { ImagePremiereVision1 } from "images";
import React from "react";

const PremiereVisionModal = ({ show, onClose }: any) => {
  return (
    <Modal onClose={onClose} show={show} bodyClassName="border-black !max-w-[660px]">
      <div className="flex flex-col items-center gap-5 p-5">
        <h6 className="text-h6-satoshi text-center max-w-[512px]">Join us at Première Vision 2025, 11-13 Feb, Paris</h6>
        <img src={ImagePremiereVision1} className="rounded-[10px]" />

        <div className="flex flex-col w-full gap-2.5">
          <a href="https://paris.premierevision.com/en" className="flex-center h-[40px] rounded-md w-full bg-green" target="_blank" rel="noreferrer">
            Check Event Details
          </a>
          <Button className="btn-secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PremiereVisionModal;
